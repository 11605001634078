var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"word-table"},[_c('section',{staticClass:"section"},[_c('h2',{staticClass:"title is-spaced maruji"},[_vm._v(_vm._s(_vm.alphabet.toUpperCase()))]),_vm._m(0),_c('table',{staticClass:"table is-fullwidth is-striped"},[_vm._m(1),_c('tbody',_vm._l((_vm.rows),function(row){return _c('tr',{key:row.en},[_c('td',[_vm._v(_vm._s(row.en))]),_c('td',[(row[_vm.language].length >= 1)?_c('span',{staticClass:"tag is-large",class:{
                'is-danger': row[_vm.language][0].gender === 'f',
                'is-success': row[_vm.language][0].gender === 'n',
                'is-info': row[_vm.language][0].gender === 'm',
              }},[_vm._v(" "+_vm._s(row[_vm.language][0].translated)+" "),_c('button',{staticClass:"button is-outlined is-inverted is-rounded is-small m-l-sm",class:{
                  'is-danger': row[_vm.language][0].gender === 'f',
                  'is-success': row[_vm.language][0].gender === 'n',
                  'is-info': row[_vm.language][0].gender === 'm',
                },on:{"click":function($event){return _vm.copy_to_clipboard(row[_vm.language][0].translated)}}},[_c('i',{staticClass:"fas fa-clipboard",attrs:{"aria-hidden":"true"}})])]):_vm._e()]),_c('td',[(row[_vm.language].length >= 2)?_c('span',{staticClass:"tag is-medium",class:{
                'is-danger': row[_vm.language][1].gender === 'f',
                'is-success': row[_vm.language][1].gender === 'n',
                'is-info': row[_vm.language][1].gender === 'm',
              }},[_vm._v(" "+_vm._s(row[_vm.language][1].translated)+" "),_c('button',{staticClass:"button is-outlined is-inverted is-rounded is-small m-l-sm",class:{
                  'is-danger': row[_vm.language][1].gender === 'f',
                  'is-success': row[_vm.language][1].gender === 'n',
                  'is-info': row[_vm.language][1].gender === 'm',
                },on:{"click":function($event){return _vm.copy_to_clipboard(row[_vm.language][1].translated)}}},[_c('i',{staticClass:"fas fa-clipboard",attrs:{"aria-hidden":"true"}})])]):_vm._e()]),_c('td',[(row[_vm.language].length >= 3)?_c('span',{staticClass:"tag is-medium",class:{
                'is-danger': row[_vm.language][2].gender === 'f',
                'is-success': row[_vm.language][2].gender === 'n',
                'is-info': row[_vm.language][2].gender === 'm',
              }},[_vm._v(" "+_vm._s(row[_vm.language][2].translated)+" "),_c('button',{staticClass:"button is-outlined is-inverted is-rounded is-small m-l-sm",class:{
                  'is-danger': row[_vm.language][2].gender === 'f',
                  'is-success': row[_vm.language][2].gender === 'n',
                  'is-info': row[_vm.language][2].gender === 'm',
                },on:{"click":function($event){return _vm.copy_to_clipboard(row[_vm.language][2].translated)}}},[_c('i',{staticClass:"fas fa-clipboard",attrs:{"aria-hidden":"true"}})])]):_vm._e()])])}),0)]),(_vm.loading === true)?_c('a',{staticClass:"button is-large is-white is-loading"}):_vm._e(),_c('div',{staticClass:"back-to-top"},[_c('a',{staticClass:"button is-large is-primary is-rounded maruji",on:{"click":_vm.back_to_top}},[_vm._v("↑")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-t-lg m-b-xl"},[_vm._v(" e.g.  "),_c('span',{staticClass:"tag is-danger"},[_vm._v("♀ feminine noun")]),_vm._v("   "),_c('span',{staticClass:"tag is-success"},[_vm._v("⚥ neuter noun")]),_vm._v("   "),_c('span',{staticClass:"tag is-info"},[_vm._v("♂ masculine noun")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("English")]),_c('th',[_vm._v("Translated")]),_c('th'),_c('th')])])}]

export { render, staticRenderFns }