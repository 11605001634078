var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"language-row"},[_c('th',[_vm._v(_vm._s(_vm.language_full))]),_c('td',[(_vm.ts.length >= 1)?_c('span',{staticClass:"tag is-large",class:{'is-danger': _vm.ts[0].gender === 'f',
              'is-success': _vm.ts[0].gender === 'n',
              'is-info': _vm.ts[0].gender === 'm'}},[_vm._v(" "+_vm._s(_vm.ts[0].translated)+" "),_c('button',{staticClass:"button is-outlined is-inverted is-rounded is-small m-l-sm",class:{'is-danger': _vm.ts[0].gender === 'f',
                'is-success': _vm.ts[0].gender === 'n',
                'is-info': _vm.ts[0].gender === 'm'},on:{"click":function($event){return _vm.copy_to_clipboard(_vm.ts[0].translated)}}},[_c('i',{staticClass:"fas fa-clipboard",attrs:{"aria-hidden":"true"}})])]):_vm._e()]),_c('td',[(_vm.ts.length >= 2)?_c('span',{staticClass:"tag is-medium",class:{'is-danger': _vm.ts[1].gender === 'f',
              'is-success': _vm.ts[1].gender === 'n',
              'is-info': _vm.ts[1].gender === 'm'}},[_vm._v(" "+_vm._s(_vm.ts[1].translated)+" "),_c('button',{staticClass:"button is-outlined is-inverted is-rounded is-small m-l-sm",class:{'is-danger': _vm.ts[1].gender === 'f',
                'is-success': _vm.ts[1].gender === 'n',
                'is-info': _vm.ts[1].gender === 'm'},on:{"click":function($event){return _vm.copy_to_clipboard(_vm.ts[1].translated)}}},[_c('i',{staticClass:"fas fa-clipboard",attrs:{"aria-hidden":"true"}})])]):_vm._e()]),_c('td',[(_vm.ts.length >= 3)?_c('span',{staticClass:"tag is-medium",class:{'is-danger': _vm.ts[2].gender === 'f',
              'is-success': _vm.ts[2].gender === 'n',
              'is-info': _vm.ts[2].gender === 'm'}},[_vm._v(" "+_vm._s(_vm.ts[2].translated)+" "),_c('button',{staticClass:"button is-outlined is-inverted is-rounded is-small m-l-sm",class:{'is-danger': _vm.ts[2].gender === 'f',
                'is-success': _vm.ts[2].gender === 'n',
                'is-info': _vm.ts[2].gender === 'm'},on:{"click":function($event){return _vm.copy_to_clipboard(_vm.ts[2].translated)}}},[_c('i',{staticClass:"fas fa-clipboard",attrs:{"aria-hidden":"true"}})])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }